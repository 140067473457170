import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ProfileStepper from "./components/business-provider/profile-components";
import ProviderDashboard from "./components/business-provider/dashboard/dashboard";
import AppointmentsDashboard from "./components/business-provider/appointments/appointmentsDashboard";
import Earnings from "./components/business-provider/earnings/earnings";
import ProviderAvailability, {
  Availability,
} from "./components/business-provider/availability/availability";
import Help from "./common/helper/help-contact-us/help";
import ContactUs from "./common/helper/help-contact-us/contactUs";
import Settings from "./components/business-provider/settings";
import Home from "./components/Home/Home";
import ProviderAppointmentDetails from "./components/business-provider/appointments/providerAppointmentDetails";
import AddServices from "./components/business-provider/add-Services/AddServices";
import Payments from "./components/business-provider/payment/Payments";
import DeleteServicePopup from "./components/business-provider/DeleteServicePopup";
import NotificationsMobile from "./components/business-provider/notifications-mobile/NotificationsMobile";
import PrivatePath from "./common/helper/PrivatePath";
import ForgotPassword from "./common/modals/auths/ForgotPassword";
import { handleRouting } from "./common/helper/handleRouting";
import { axiosInstance } from "./controller/axiosInterceptor";
import { getFCMToken } from "./common/helper/firebase";
import "./App.scss";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import TermsAndConditionPage from "./components/TermsAndConditionPage";
import HomeNew from "./components/HomeNew/HomeNew";
import "./styles/responsive.css";
const stripePromise = loadStripe(
  "pk_live_51NYsfVAiCjzG5gJivQRz9BKY7OffcoG4aqhJ5uyhxHKhuDfat2mWNJh0Bo4wlOqvHK5usBDGumwBBwPMhrxSv7fB00u72s2esU"
);

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [businessData, setBusinessData] = useState({});
  const [screenNo, setScreenNo] = useState(0);

  useEffect(() => {
    // onMessage(messaging, (payload) => {
    //   message.info('Notification received.', JSON.stringify(payload.data), 1000)
    // });
    localStorage.setItem("Tab", "tmp_key-0");
  }, []);

  useEffect(() => {
    const screen = localStorage.getItem("screen");
    if (screen) handleRouting(screen, navigate);
    if (["/settings", "/business-provider/dashboard"].includes(pathname))
      getProfileData();
  }, [pathname]);

  const getProfileData = () => {
    axiosInstance
      .post("/business/getProfile")
      .then((res) => {
        const userDetail = res.data.output?.businessData;
        setBusinessData(userDetail);
        const user =
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user"));
        setScreenNo(userDetail.screen);
        // get fcmToken if not saved in backend
        if (!userDetail.fcmToken) getFCMToken();
        if (userDetail && userDetail.screen && !user) {
          handleRouting(userDetail.screen, navigate);
        }
        localStorage.setItem("user", JSON.stringify(userDetail));
        localStorage.setItem("screen", userDetail.screen);
      })
      .catch((err) => {
        // message.error(err.data?.error?.message);
      });
  };

  return (
    <Routes>
      <Route path="/" element={<HomeNew />} exact />
      <Route
        path="/profile-details"
        element={
          <PrivatePath>
            <Elements stripe={stripePromise}>
              <ProfileStepper screenNo={screenNo} />
            </Elements>
          </PrivatePath>
        }
        exact
      />
      <Route
        path="/business-provider/dashboard"
        element={
          <PrivatePath>
            <ProviderDashboard />
          </PrivatePath>
        }
        exact
      />

      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route
        path="/business-provider/appointments-dashboard"
        element={
          <PrivatePath>
            <AppointmentsDashboard />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/business-provider/earnings"
        element={
          <PrivatePath>
            <Earnings />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/business-provider/availability"
        element={
          <PrivatePath>
            <ProviderAvailability settingPage />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/availability"
        element={
          <PrivatePath>
            <Availability />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/business-provider/appointments/appointment-details/:id"
        element={
          <PrivatePath>
            <ProviderAppointmentDetails />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/help"
        element={
          <PrivatePath>
            <Help />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/contact-us"
        element={
          <PrivatePath>
            <ContactUs />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/settings"
        element={
          <PrivatePath>
            <Elements stripe={stripePromise}>
              <Settings businessData={businessData} />
            </Elements>
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/services/top-listing"
        element={
          <PrivatePath>
            <AddServices />
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/services/top-listing/add-payments"
        element={
          <PrivatePath>
            <Elements stripe={stripePromise}>
              <Payments />
            </Elements>
          </PrivatePath>
        }
        exact
      />

      <Route
        path="/decline-service"
        element={
          <PrivatePath>
            <DeleteServicePopup />
          </PrivatePath>
        }
      />

      <Route
        path="/notifications"
        element={
          <PrivatePath>
            <NotificationsMobile />
          </PrivatePath>
        }
      />

      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

      <Route path="/terms-condition" element={<TermsAndConditionPage />} />
    </Routes>
  );
}

export default App;
